export default {
    setSessionStorage(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value))//value可能是一个JSON对象,存储需要将JSON转换为字符串
    },
    getSessionStorage(key) {
        let str = sessionStorage.getItem(key)
        if (str == '' || str == null || str == 'null' || str == undefined) {
            return null
        } else {
            return JSON.parse(str)
        }
    },
    removeSessionStorage(key) {
        sessionStorage.removeItem(key)
    },
    setLocalStorage(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    getLocalStorage(key) {
        let str = localStorage.getItem(key)
        if (str == '' || str == null || str == 'null' || str == undefined) {
            return null
        } else {
            return JSON.parse(str)
        }
    },
    removeLocalStorage(key) {
        localStorage.removeItem(key)
    },
    resetForm(formName) {
        this.$refs[formName].resetFields()
    }
}