export default {
    //状态码
    HTTP_SUCCESS_CODE: 1,
    HTTP_ERROR_CODE: 2,
    HTTP_NOLOGIN_CODE: 3,

    // baseUrl: "http://localhost:10001/hezhou",
    baseUrl: "http://www.sy168.top:10001/hezhou",
    tokenKey: "hezhouToken",
    uploadUrl: "/api/upload",
    excelDownload: "http://www.sy168.top:10001/hezhou/product/output",
    // excelDownload: "http://localhost:10001/hezhou/product/output",
    excelDownload2: "http://www.sy168.top:10001/hezhou/payment/output"
    // excelDownload2: "http://localhost:10001/hezhou/payment/output"
}