import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import http from './request'
//引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
//引入自定义样式
import '@/assets/css/style.css'
import '@/assets/css/color.css'
//引入fontawsome
import 'font-awesome/css/font-awesome.min.css'

Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.use(ElementUI)

const app = new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

console.log(app);