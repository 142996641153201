//导入必要的依赖
import axios from "axios";
import common from '@/common'
import router from "@/router";
import constants from "@/constants";
import {
    Message
} from 'element-ui'

//进行axios全局配置
const myaxios = axios.create({
    timeout: 500000,//设置超时时间
})

//设置后端路径
myaxios.defaults.baseURL = constants.baseUrl


//设置请求拦截
myaxios.interceptors.request.use(config => {
    //获取本地存储的token
    config.headers[constants.tokenKey] = common.getLocalStorage(constants.tokenKey)
    return config
})

//设置响应全局处理
myaxios.interceptors.response.use(
    res => {
        // console.log(res);
        let result = res.data
        if (result.code == constants.HTTP_SUCCESS_CODE) {
            return res
        } else {
            if (result.code == constants.HTTP_NOLOGIN_CODE) {
                //token异常则清空本地token并重新前往登录
                Message.error(result.msg ? result.msg : "token异常,请重新登录")
                common.removeLocalStorage(constants.tokenKey)

                const timer = setInterval(() => {
                    router.push({
                        path: '/login'
                    })
                        .catch(err => {

                        })
                    clearInterval(timer)
                }, 2000)

            }
            if (result.code == constants.HTTP_ERROR_CODE) {
                Message.error(result.msg ? result.msg : "系统执行异常")
                console.log("发生错误后的响应:", res);
            }
            Message.error(result.msg ? result.msg : "系统执行异常")
            console.log("发生错误后的响应:", res);

            return res
        }
    },
    (err) => {
        console.log("网络异常:", err);
    })

//导出
export default myaxios;