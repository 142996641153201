import Vue from 'vue'
import VueRouter from 'vue-router'
import constants from '@/constants'
import common from '@/common'
import store from '@/store'
import myaxios from '@/request'
import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: () => import('@/views/Home.vue'),
    redirect: '/index',
    children: [{
      path: "/addProduct",
      component: () => import('@/views/ProductManage/AddProduct.vue')
    }, {
      path: "/index",
      component: () => import('@/views/Index.vue')
    }, {
      path: '/usercenter',
      component: () => import('@/views/UserCenter.vue')
    }, {
      path: "/orderDetail",
      component: () => import('@/views/OrderManage/OrderDetail.vue')
    }]
  },
  {
    path: "/login",
    component: () => import('@/views/Login.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

router.beforeEach((to, from, next) => {
  let token = common.getLocalStorage(constants.tokenKey)
  // console.log(token);
  if (to.path == '/login') {
    next()
    return
  } else {
    //如果本地有token,且前往路径不为登录页面则加载菜单
    if (token) {
      //加载菜单数据
      let hasRoute = store.state.hasRoute
      let menus = store.state.menus
      if (!hasRoute) {
        //菜单数据不存在则加载菜单数据
        let routes = router.options.routes
        let url = "/system/loadMenu"
        myaxios.post(url, {}, true)
          .then(res => {
            if (res.data.code == constants.HTTP_SUCCESS_CODE) {
              let menuData = res.data.data
              store.commit("SET_MENUS", menuData)
              console.log("从后端获取的菜单数据:", menuData);
              menuData.forEach(menu => {
                if (menu.children.length != 0) {
                  menu.children.forEach(child => {
                    let route = menuToRoute(child)
                    console.log("子路由:", route);
                    if (route) {
                      //将创建的路由对象添加到routes
                      routes[0].children.push(route)
                    }
                  })
                  console.log("系统管理路由:", routes[0]);
                }

              });
              //将路由数组重新放回到router对象
              store.commit("SET_HASROUTE", true)
              console.log("路由:", routes);
              router.addRoutes(routes)
              console.log("路由对象:", router);

              next({
                path: to.path
              })
              return
            }
          })
          .catch(err => { console.log(err); })
      }
      // TODO
      next()
    } else {
      //系统内不存在token则重新登陆
      Message.warning("请登陆后再访问本系统")
      router.push({
        path: "/login"
      })
        .catch(err => { })
      return
    }
  }



})


//封装方法：由菜单数据创建出 路由配置对象
const menuToRoute = (child) => {
  if (!child.component) {
    //菜单如果component组件属性为空
    return null
  }
  //创建路由配置对象
  let route = {
    name: child.name,
    path: child.path,
    meta: {  //配置元数据   做tab选项卡
      icon: child.icon,  //图标
      title: child.title  //菜单标题

    }
  }
  route.component = () => import('@/views' + child.component + '.vue')
  return route
}